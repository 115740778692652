import { environment } from "../../../environments/environment";

const apiPath = environment.apiPath;

export const APIConstant = {
    basePath: apiPath,
    busstops: `${apiPath}/transport/busstops`,
    buses: `${apiPath}/transport/buses`,
    maintenance: `${apiPath}/transport/maintenance`,
    insurance: `${apiPath}/transport/insurance`,
    route: `${apiPath}/transport/busroute`,
    routeconfig: `${apiPath}/transport/routeconfig`,
    trip: `${apiPath}/transport/trips`,
    tripschedule: `${apiPath}/transport/tripschedule`,
    classroomList: `${apiPath}/transport/studenttripmapping/by-classroom-list`,
    unmappedStudents : `${apiPath}/transport/studenttripmapping/un-mapped`,
    busTypes: `${apiPath}/lookup/?fieldName=BUS_TYPE`,
    busFare: `${apiPath}/transport/busfare`,
    addBusFare: `${apiPath}/transport/busfare/list`,
    inwardTimeSlot: `${apiPath}/transport/bustrips/inward-time-slots`,
    outwardTimeSlot: `${apiPath}/transport/bustrips/outward-time-slots`,
    unMappedStudents: `${apiPath}/transport/studenttripmapping/un-mapped-by-stopId`,
    busTripByRouteType: `${apiPath}/transport/bustrips/by-routeType`,
    autoAssignTrip: `${apiPath}/transport/studenttripmapping/auto-assign`,
    manualAssignTrip: `${apiPath}/transport/studenttripmapping/manual-assign`,
    studentTripMapping: `${apiPath}/transport/studenttripmapping`,
    allStudentByTripReport: `${apiPath}/transport/studenttripmapping/by-tripId`,
    allStudentByStopReport: `${apiPath}/transport/studenttripmapping/by-stopId`,
    changeTrip: `${apiPath}/transport/studenttripmapping/change-trip`,
    feeDue: `${apiPath}/transport/studenttripmapping/transport-dues`,
    stopwiseStudentCount: `${apiPath}/transport/studenttripmapping/stopwise-count`,
    // trips
    busTrips:`${apiPath}/transport/trips`,
    busStops:`${apiPath}/transport/trips/stops`,
    deleteStop:`${apiPath}/transport/trips/stops`,
    changeOrder:`${apiPath}/transport/trips/stops/change-stop-order`,
    studentPreferences: `${apiPath}/transport/student-preference`,
    autoAssignTrips:`${apiPath}/transport/studenttripmapping/auto-assign`,
    transportConfig:`${apiPath}/transport/config`,
    getProfile:`${apiPath}/security/users/get-driver-and-helper-profiles`

};

export const PublicAPI = [];
