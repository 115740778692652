import { Injectable } from '@angular/core';
import { APIConstant } from '../constant';

import { Observable } from 'rxjs';
import { Student, APIPagedResponse, SecurityUser } from 'models';
import { BaseService, CRUDService, CommonUtility, AppConfigService, CommonAPIConstant } from 'projects/common/src/lib';

@Injectable({
    providedIn: 'root',
})
export class TransportService extends CRUDService<Student> {
    constructor(protected baseService: BaseService, private configService: AppConfigService) {
        super(baseService);
    }

    getAllBusStops(data: any): Observable<any> {
        const query = CommonUtility.convertObjectToParams(data);
        return this.baseService.get<any>(`${APIConstant.busstops}?${query}`);
    }

    getAllBusStopsNoPage = () => {
        return this.baseService.get<any>(`${APIConstant.busstops}?isPaginationRequired=false`);
    }

    deleteBusStops(busStopId: number): Observable<APIPagedResponse<boolean>> {
        return this.baseService.delete(`${APIConstant.busstops}?busStopId=${busStopId}`);
    }

    updateBusStops = (busStopData: any) => {
        return this.baseService.put(`${APIConstant.busstops}`, busStopData);
    }

    addBusStops = (busStopData: any) => {
        return this.baseService.post(`${APIConstant.busstops}`, busStopData);
    }

    getAllBus(): Observable<any> {
        return this.baseService.get<any>(`${APIConstant.buses}?page=0&size=10`);
    }

    deleteBus(busId: number): Observable<APIPagedResponse<boolean>> {
        return this.baseService.delete(`${APIConstant.buses}?busProfileId=${busId}`);
    }

    updateBus = (busStopData: any) => {
        return this.baseService.put(`${APIConstant.buses}`, busStopData);
    }

    addBus = (busStopData: any) => {
        return this.baseService.post(`${APIConstant.buses}`, busStopData);
    }

    getAllBusMaintenance(busProfileId: any): Observable<any> {
        return this.baseService.get<any>(`${APIConstant.maintenance}?busProfileId=${busProfileId}`);
    }

    deleteBusMaintenance(maintenanceHistoryId: number): Observable<APIPagedResponse<boolean>> {
        return this.baseService.delete(`${APIConstant.maintenance}?maintenanceHistoryId=${maintenanceHistoryId}`);
    }

    updateBusMaintenance = (busStopData: any) => {
        return this.baseService.put(`${APIConstant.maintenance}`, busStopData);
    }

    addBusMaintenance = (busStopData: any) => {
        return this.baseService.post(`${APIConstant.maintenance}`, busStopData);
    }


    getAllBusinsurance(busProfileId): Observable<any> {
        return this.baseService.get<any>(`${APIConstant.insurance}?busProfileId=${busProfileId}`);
    }

    deleteBusinsurance(insuranceId: number): Observable<APIPagedResponse<boolean>> {
        return this.baseService.delete(`${APIConstant.insurance}?insuranceId=${insuranceId}`);
    }

    updateBusinsurance = (busStopData: any) => {
        return this.baseService.put(`${APIConstant.insurance}`, busStopData);
    }

    addBusinsurance = (busStopData: any) => {
        return this.baseService.post(`${APIConstant.insurance}`, busStopData);
    }

    getAllBusroute(): Observable<any> {
        return this.baseService.get<any>(`${APIConstant.route}`);
    }

    deleteBusroute(routeId: number): Observable<APIPagedResponse<boolean>> {
        return this.baseService.delete(`${APIConstant.route}?routeId=${routeId}`);
    }

    updateBusroute = (busStopData: any) => {
        return this.baseService.put(`${APIConstant.route}`, busStopData);
    }

    addBusroute = (busStopData: any) => {
        return this.baseService.post(`${APIConstant.route}`, busStopData);
    }

    getAllBusrouteconfig(routeId: number): Observable<any> {
        return this.baseService.get<any>(`${APIConstant.routeconfig}?routeId=${routeId}`);
    }

    deleteBusrouteconfig(busRouteConfigId: number): Observable<APIPagedResponse<boolean>> {
        return this.baseService.delete(`${APIConstant.routeconfig}?busRouteConfigId=${busRouteConfigId}`);
    }

    updateBusrouteconfig = (busStopData: any) => {
        return this.baseService.put(`${APIConstant.routeconfig}`, busStopData);
    }

    addBusrouteconfig = (busStopData: any) => {
        return this.baseService.post(`${APIConstant.routeconfig}`, busStopData);
    }

    getAllBustrip(): Observable<any> {
        return this.baseService.get<any>(`${APIConstant.trip}`);
    }

    getAllBustripByRouteId(routeId): Observable<any> {
        return this.baseService.get<any>(`${APIConstant.trip}/by-route?routeId=${routeId}`);
    }

    deleteBustrip(tripId: number): Observable<APIPagedResponse<boolean>> {
        return this.baseService.delete(`${APIConstant.trip}?tripId=${tripId}`);
    }

    updateBustrip = (busStopData: any) => {
        return this.baseService.put(`${APIConstant.trip}`, busStopData);
    }

    addBustrip = (busStopData: any) => {
        return this.baseService.post(`${APIConstant.trip}`, busStopData);
    }

    updateBustripschedule = (data: any) => {
        return this.baseService.post(`${APIConstant.tripschedule}/list`, data);
    }

    getStudentTripMappingByClassroomList(data: any): Observable<any> {
        return this.baseService.post<any>(`${APIConstant.classroomList}`, data);
    }

    getAllBusTypes(): Observable<any> {
        return this.baseService.get<any>(`${APIConstant.busTypes}`);
    }

    getAllBusTypeFares(): Observable<any> {
        return this.baseService.get<any>(`${APIConstant.busFare}`);
    }

    addBusTypeFares(data: any): Observable<any> {
        return this.baseService.post<any>(`${APIConstant.addBusFare}`, data);
    }
    updateBusTypeFares(data: any): Observable<any> {
        return this.baseService.put<any>(`${APIConstant.addBusFare}`, data);
    }

    getInwardTimeSlot(): Observable<any> {
        return this.baseService.get<any>(`${APIConstant.inwardTimeSlot}`);
    }

    getOutwardTimeSlot(): Observable<any> {
        return this.baseService.get<any>(`${APIConstant.outwardTimeSlot}`);
    }

    getUnMappedStudentList(params: any): Observable<any> {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.get<any>(`${APIConstant.unMappedStudents}?${query}`);
    }

    getAllBusTripsByRouteType(params: any): Observable<any> {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.get<any>(`${APIConstant.busTripByRouteType}?${query}`);
    }

    autoAssignTrip(isOverRide: any): Observable<any> {
        const query = CommonUtility.convertObjectToParams({ isOverRide });
        return this.baseService.put<any>(`${APIConstant.autoAssignTrip}?${query}`, null);
    }

    manualAssignTrip(data: any, tripId: any): Observable<any> {
        const query = CommonUtility.convertObjectToParams({ tripId });
        return this.baseService.put<any>(`${APIConstant.manualAssignTrip}?${query}`, data);
    }

    addOrUpdateStudentTripMapping(data: any): Observable<any> {
        return this.baseService.post<any>(`${APIConstant.studentTripMapping}`, data);
    }

    getAllStudentByTripReport(tripId: any): Observable<any> {
        const query = CommonUtility.convertObjectToParams({ tripId });
        return this.baseService.get<any>(`${APIConstant.allStudentByTripReport}?${query}`);
    }

    getAllStudentByStopReport(params: any): Observable<any> {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.get<any>(`${APIConstant.allStudentByStopReport}?${query}`);
    }

    addOrUpdateBusTripScheduleList(data: any): Observable<any> {
        return this.baseService.post<any>(`${APIConstant.tripschedule}/list`, data);
    }

    getBusTripSchedule(busTripScheduleId: number): Observable<any> {
        return this.baseService.get<any>(`${APIConstant.tripschedule}/uncheck?busTripScheduleId=${busTripScheduleId}`);
    }

    getAllStudentsWithoutTrip(): Observable<any> {
        return this.baseService.get<any>(`${APIConstant.unmappedStudents}`);
    }

    changeTrip(data: any){        
        return this.baseService.put(`${APIConstant.changeTrip}`,data);
    }

    getFeeDue(tripId: number): Observable<any> {
        return this.baseService.get<any>(`${APIConstant.feeDue}/?tripId=${tripId}`);
    }

    getStopwiseStudentCount(): Observable<any> {
        return this.baseService.get<any>(`${APIConstant.stopwiseStudentCount}`);
    }

    getTripSchedule(tripId: number): Observable<any> {
        return this.baseService.get<any>(`${APIConstant.tripschedule}/?tripId=${tripId}`);
    }
    getTrips():Observable<any>{
        return this.baseService.get<any>(`${APIConstant.busTrips}`);
    }
    getBusStops(tripId:number):Observable<any>{
        return this.baseService.get<any>(`${APIConstant.busStops}?tripId=${tripId}`);
    }
    
    deleteBusStop(tripStopId: number): Observable<APIPagedResponse<boolean>> {
        return this.baseService.delete(`${APIConstant.busStops}?tripStopId=${tripStopId}`);
    }
    // ADD BUS STOP
    updateBusStop = (busStopData: any) => {
        return this.baseService.put(`${APIConstant.busStops}`, busStopData);
    }

    addBusStop = (busStopData: any) => {
        return this.baseService.post(`${APIConstant.busStops}`, busStopData);
    }
      changeStopOrder(params: any): Observable<any> {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.put(`${APIConstant.changeOrder}?${query}`, {});
    }
      
    getStudentPreferences(isInclude: boolean):Observable<any>{
        return this.baseService.get<any>(`${APIConstant.studentPreferences}?includeStudentProfile=${isInclude}`);
    }

    editStudentPreferences(data: any): Observable<any> {
        return this.baseService.put<any>(`${APIConstant.studentPreferences}`, data);  
    }

    getEnrolledStudents(params: any, data: any): Observable<any> {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.post<any>(`${APIConstant.studentPreferences}/search?${query}`, data);
    }

    enrollStudent(data: any): Observable<any> {
        return this.baseService.post<any>(`${APIConstant.studentPreferences}`, data);
    }
    autoAssignBusTrips(data:any): Observable<any> {
        return this.baseService.post<any>(`${APIConstant.autoAssignTrips}`, data);
    }
    getTransportConfig():Observable<any>{
        return this.baseService.get<any>(`${APIConstant.transportConfig}`);
    }

    addConfig(data:any):Observable<any>{
        return this.baseService.post<any>(`${APIConstant.transportConfig}`,data)
    }
    updateConfig(data:any):Observable<any>{
        return this.baseService.put<any>(`${APIConstant.transportConfig}`,data)
    }
    getDriverAndHelperProfile():(Observable<any>){
        return this.baseService.get<any>(`${APIConstant.getProfile}`)
    }
}