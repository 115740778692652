<div class="container-fluid">
    <div class="app-box mb-5">
      <div class="title-row">
        <span class="title">Transport Settings</span>
        <div class="buttons d-inline-flex">
          <button *ngIf="!isEdit && !isNoConfigAvailable" class="btn app-btn" (click)="edit()"><i class="fa fa-pencil"></i>&nbsp;Edit</button>
          <button *ngIf="isEdit" class="btn app-btn" (click)="save()"><i class="fa fa-floppy-o" aria-hidden="true"></i>&nbsp;Save</button>
          <button *ngIf="isEdit" class="btn app-btn outline" (click)="cancel()"><i class="fa fa-times"></i>&nbsp;Cancel</button>
        </div>
      </div>
      <form [formGroup]="transportForm" *ngIf="!isNoConfigAvailable || isEdit; else noConfigTemplate">
        <div class="form-group">
          <div class="row m-2">
            <div class="col-sm-4">
              <label for="schoolLocation">School Location
                <i class="fas fa-info-circle" title="Geo-Coordinates"></i>
              </label>
              <input formControlName="schoolLocation" type="text" id="schoolLocation" class="form-control" [readonly]="!isEdit" />
            </div>
            <div class="col-sm-4">
              <label for="waitTimeInSeconds">Max wait time at each stop (seconds)</label>
              <input formControlName="waitTimeInSeconds" type="number" id="waitTimeInSeconds" class="form-control" [readonly]="!isEdit" />
              <div *ngIf="transportForm.get('waitTimeInSeconds').hasError('maxTimeExceeded') && transportForm.get('waitTimeInSeconds').touched" class="text-danger">
                Please recheck your input
              </div>
            </div>
            <div class="col-sm-4">
              <label for="maxTravelTimeInSeconds">Max travel time for any bus (seconds)</label>
              <input formControlName="maxTravelTimeInSeconds" type="text" id="maxTravelTimeInSeconds" class="form-control" [readonly]="!isEdit" />
              <div *ngIf="transportForm.get('maxTravelTimeInSeconds').hasError('maxTravelTimeInvalid') && transportForm.get('waitTimeInSeconds').touched" class="text-danger">
                Please recheck your input
              </div>
            </div>
          </div>
          <div class="form">
          <div class="row m-2">
            <div class="col-sm-4">
              <label for="earlyDepartureWindowInSeconds">Early Departure window (seconds)
                <i class="fas fa-info-circle" title="To determine if driver can start the trip early or not"></i>
              </label>
              <input formControlName="earlyDepartureWindowInSeconds" type="number" id="earlyDepartureWindowInSeconds" class="form-control" [readonly]="!isEdit" />
              <div *ngIf="transportForm.get('earlyDepartureWindowInSeconds').hasError('earlyDepartureValidator') && transportForm.get('earlyDepartureWindowInSeconds').touched" class="text-danger">
                Please recheck your input
              </div>
            </div>
            <div class="col-sm-4">
              <label for="inTime">When should the bus reach school (Inward trip)</label>
              <input formControlName="inTime" type="time" id="inTime" class="form-control" [readonly]="!isEdit" />
            </div>
            <div class="col-sm-4">
              <label for="outTime">When should the bus start from the school (Outward trip)</label>
              <input formControlName="outTime" type="time" id="outTime" class="form-control" [readonly]="!isEdit" />
            </div>
          </div>
        </div>
    </div>
      </form>
  
      <ng-template #noConfigTemplate>
        <div class="form-group">
          <div class="empty-punch col-md-12">
            <img src="assets/images/empty-punch.png" alt="Empty Punch" />
            <p>No configuration available. Click here to 
              <a href="#" (click)="addNewConfig()">add</a>
            </p>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  