import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

export const CommonConstant = {
    token: 'token',
    user: 'user',
    studentId: 'studentId',
    defaultPageSize: 10,
    reportDownloadPageSize: 1000,
    importStudentExcelFile: 'https://akshar-public-files.s3.ap-south-1.amazonaws.com/Import+Templates/Student+Import.xlsx',
};

export const Images = {
    user: 'assets/images/user.png',
    book: 'assets/images/add-book.png'
}

export const FeedbackCategory: string[] = ["Appreciation", "Complaint", "Other"];