import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { TransportService } from "../../core/service/transport.service";

@Component({
  templateUrl: './trans-set.component.html',
  styleUrls: ['./trans-set.component.scss']
})
export class TransSettComponent implements OnInit {
  isEdit: boolean = false;
  isNoConfigAvailable: boolean = false;
  transportForm: FormGroup;
  isFormSubmitted:boolean = false;

  constructor(
    private fb: FormBuilder,
    private transportService: TransportService,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService
  ) { }

  ngOnInit() {
    this.initializeForm();
    this.getTransportConfig();
  }

  initializeForm() {
    this.transportForm = this.fb.group({
      schoolLocation: [null],
      waitTimeInSeconds: [null, [Validators.required, this.maxTimeValidator]],
      earlyDepartureWindowInSeconds: [null, [Validators.required,this.earlyDepartureValidator]],
      maxTravelTimeInSeconds: [null, [Validators.required,this.maxTravelTimeValidator]],
      inTime: [null],
      outTime: [null]
    });
    this.transportForm.disable();
  }

  maxTimeValidator(control: FormControl) {
    return control.value > 100 ? { 'maxTimeExceeded': true } : null;
  }
  maxTravelTimeValidator(control: FormControl) {
    if (control.value > 7200 || control.value < 1000) {
      return { 'maxTravelTimeInvalid': true };
    }
    return null;
  }

  earlyDepartureValidator(control: FormControl) {
    if (control.value > 1000 || control.value < 50) {
      return { 'earlyDepartureInvalid': true };
    }
    return null;
  }

  getTransportConfig() {
    this.spinner.show();
    this.transportService.getTransportConfig().subscribe(
      (config) => {
        this.spinner.hide();
        if (config) {
          this.transportForm.patchValue(config);
          this.isNoConfigAvailable = false;
        } else {
          this.isNoConfigAvailable = true;
        }
      },
      (error) => {
        this.spinner.hide();
        this.isNoConfigAvailable = true;
        this.toaster.error('No transport configuration available', 'Error');
      }
    );
  }

  edit() {
    this.isEdit = true;
    this.transportForm.enable();
  }
  save() {
    this.isFormSubmitted = true;

    if (this.transportForm.invalid) {
        return;
    }

    const formData = this.transportForm.value;

    const formatTimeWithSeconds = (time: string) => {
        return time.length === 5 ? `${time}:00` : time;
    };

    const inTimeWithSeconds = formatTimeWithSeconds(formData.inTime);
    const outTimeWithSeconds = formatTimeWithSeconds(formData.outTime);

    const configData = {
        ...formData,
        inTime: inTimeWithSeconds,
        outTime: outTimeWithSeconds
    };

    this.spinner.show();

    const request = this.isNoConfigAvailable 
        ? this.transportService.addConfig(configData) 
        : this.transportService.updateConfig(configData);

    request.subscribe(
        () => {
            this.spinner.hide();
            const successMessage = this.isNoConfigAvailable 
                ? 'Transport configuration added successfully' 
                : 'Transport configuration updated successfully';
            this.toaster.success(successMessage);
            this.isNoConfigAvailable = false;
            this.isEdit = false;
            this.transportForm.disable();
        },
        (error) => {
            this.spinner.hide();
            const errorMessage = this.isNoConfigAvailable 
                ? 'Error adding transport configuration' 
                : 'Error updating transport configuration';
            this.toaster.error(errorMessage);
        }
    );
}


  cancel() {
    this.isEdit = false;
    this.getTransportConfig();
    this.transportForm.disable();
  }

  addNewConfig() {
    this.isEdit = true; 
    this.isNoConfigAvailable = true; 
    this.transportForm.reset(); 
    this.transportForm.enable(); 
  }
}
